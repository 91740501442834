import styled from "styled-components";

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(2px);
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 1000;
`;

const ModalOptionContainer = styled.div`
  width: 100%;
  max-width: 450px;
  border-radius: 10px 10px 0 0;
  padding: 10px 20px 20px 20px;
  background-color: var(--color-gris-normal);
  position: relative;

  &::before {
    content: "";
    display: block;
    width: 40px;
    height: 4px;
    background-color: var(--fondo-gris-medio);
    border-radius: 2px;
    margin: 0 auto 10px auto;
  }
`;

const Option = styled.div`
  display: flex;
  align-items: center;
  padding: 12px;
  margin: 8px 0;
  cursor: pointer;
  border-radius: 5px;
  background-color: var(--fondo-blanco-medio);
  transition: background-color 0.3s ease;
  font-family: var(--fuente-proxima-regular);
  border: 1px solid var(--fondo-gris-claro);

  &:hover {
    background-color: var(--fondo-gris-medio);
  }

  &.delete {
    background-color: var(--color-error-normal);
    color: white;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .icon {
    margin-right: 10px;
    height: 18px;
    width: 18px;
    color: var(--fondo-verde-marca);
  }
`;

const Delete = styled(Option)`
  background-color: var(--color-error-normal);
  color: white;

  &:hover {
    background-color: #c40000;
  }
`;

export { ModalBackground, ModalOptionContainer, Option, Delete };
