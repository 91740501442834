const privacidad = `<h1 data-fontsize="36" data-lineheight="54">POLÍTICAS DE PRIVACIDAD</h1>
<p>Proteger su privacidad es muy importante para HDI Seguros. Valoramos su confianza y deseamos ayudarle a comprender la manera en que recopilamos, protegemos y utilizamos su información personal.</p>
<p>Política de privacidad aplicable a los Estados Unidos Mexicanos (“México”): Esta política de privacidad se aplica solamente a personas que habiten en territorio mexicano.</p>
<h4 data-fontsize="21" data-lineheight="33">CÓMO UTILIZAMOS Y PROTEGEMOS LA INFORMACIÓN PERSONAL</h4>
<p>En HDI Seguros, proteger su privacidad es muy importante. El propietario y operador de&nbsp;<a href="https://web.archive.org/web/20170108231852/http://hdi.com.mx/">hdi.com.mx</a>&nbsp;es HDI Seguros S.A de C.V.. Valoramos su confianza y deseamos que comprenda qué clase de información recopilamos, de qué manera la protegemos y cómo la utilizamos. Tratamos la información personal con confidencialidad y en conformidad con esta Política de privacidad.</p>
<p>Esta Política de privacidad está diseñada para brindarle información sobre la manera en que recopilamos, utilizamos y revelamos la información personal que obtenemos cuando visita el sitio&nbsp;<a href="http://hdimexicoinsurance.com/index.aspx">hdimexicoinsurance.com</a> en Internet. Esta Política de privacidad se aplica solamente a personas que habiten en territorio mexicano cuando interactúan con nuestro sitio Web.</p>
<h4 data-fontsize="21" data-lineheight="33">NUESTRA POLÍTICA DE PRIVACIDAD</h4>
<p>Ésta es nuestra Política de privacidad:</p>
<p>Describiremos qué clase de información personal recopilamos, de qué manera la protegemos y cómo podemos utilizarla.<br>
Mantendremos medidas de seguridad física, electrónica y de procedimientos para proteger la información personal que obtenemos sobre nuestros clientes y aquellos que utilizan y visitan nuestro sitio Web.<br>
No compartiremos la información personal con compañías externas ni proveeremos informes de consumo a otras compañías afiliadas, excepto según lo estipulado más adelante.<br>
Requeriremos que las compañías externas que nos prestan servicios y a las cuales revelamos información personal se adhieran a los estándares de nuestra Política de privacidad.</p>
<p>A continuación encontrará más detalles sobre nuestras prácticas de uso y seguridad de su información personal. Esta Política de privacidad incluye ejemplos del tipo de información que podemos recopilar y la clase de compañías y demás entidades con las que podemos compartir la información. Estos ejemplos son meramente ilustrativos y no deben considerarse como una descripción exhaustiva de nuestras prácticas de recopilación y divulgación de información.</p>
<h4 data-fontsize="21" data-lineheight="33">RECOPILACIÓN DE INFORMACIÓN</h4>
<p>Información en línea y recopilación de datos. Puede visitarnos en Internet sin necesidad de identificarse o revelar información personal, inclusive su dirección de correo electrónico. En este caso, nuestros servidores de red obtienen el nombre del dominio que utilizó para acceder a Internet, tal como “aol.com” o “yahoo.com,” el sitio Web del que proviene y el que visite posteriormente. Podemos seleccionar compañías externas que prestan servicios en representación de nuestra firma y utilizan esta información para registrar el número de visitas, el tiempo promedio de visita, las páginas visitadas y otros datos estadísticos sobre quienes ingresan a nuestro sitio Web en general. También podemos utilizar estos datos para seguir el rendimiento del sitio y facilitar su uso.</p>
<p>Podemos recopilar las estadísticas reunidas sobre nuestros clientes, ventas, patrones de tráfico y servicios y entregarlas a terceros. Sin embargo, cuando revelamos estas estadísticas a terceros, no incluimos datos personales de identificación, a menos que hubiera acordado recibir información personalizada. Además, algunas porciones de nuestro sitio Web requieren información personal para fines específicos, tales como brindarle información que hubiera solicitado. La información recopilada sobre usted puede incluir su nombre, domicilio, teléfono, número de fax o dirección de correo electrónico. Para fines de diversidad de proveedor de nuestro sitio, recopilamos obligatoriamente información sobre género y origen étnico, como por ejemplo, si el propietario de su empresa es de sexo femenino.</p>
<p>Cookies<strong>.</strong>&nbsp;Los cookies son pequeños grupos de datos (los “cookies”), almacenados por su buscador de Internet en el disco duro de su unidad central de procesos (“CPU”). Al igual que miles de otros sitios Web, utilizamos los cookies para mejorar su experiencia de visualización y medir su uso de sitios Web. Con la ayuda de los cookies, podemos presentarle ofertas personalizadas o contenidos de su interés. También podemos utilizar los cookies para reconocerle en las siguientes visitas o recordar su nombre de usuario para que no necesite reinsertarlo cada vez que visita nuestro sitio. Puede configurar su buscador para que le notifique cuando recibe un cookie o impedir el envío de los mismos. Sin embargo, debe tener en cuenta que al no aceptar cookies puede limitar la funcionalidad que le ofrecemos al visitar nuestro sitio.</p>
<p>Vínculos a otros sitios Web. No asumimos responsabilidad alguna por las prácticas de recopilación de información de otros sitios Web a los que se vincule desde nuestro sitio Web. No podemos garantizar la manera en que esas partes utilizan los cookies o si colocan en su CPU cookies que puedan identificarle personalmente. Recomendamos analizar cuidadosamente las políticas de privacidad de cada sitio Web que visita para comprender la manera en que recopilan, utilizan y revelan información.</p>
<p>Comunicaciones por correo electrónico.Ocasionalmente, podemos enviarle correos electrónicos con información que podría resultarle útil, inclusive información sobre nuestros productos y servicios u ofertas de otras compañías afiliadas o de terceros. Podrá optar por no recibir estos mensajes electrónicos si así lo desea.</p>
<p>Encriptación.&nbsp;Utilizamos herramientas de encriptación y autenticación para proteger la información que reunimos en nuestro sitio Web. Sin embargo, es posible que los correos electrónicos fuera de nuestro sitio Web, no se encuentren protegidos. Si desea enviarnos un correo electrónico que contenga información altamente confidencial, tal como su número de tarjeta de crédito, es preferible que nos llame por teléfono o envíe la información por correo regular.</p>
<p>Visitantes / Usuarios registrados. Cuando visita nuestro sitio Web, puede acceder a ciertas funciones sin necesidad de revelar su identidad u otra información personal. No reuniremos ningún dato sobre usted cuando utilice estas funciones. A fin de acceder y utilizar otras funciones, es posible que deba registrarse. Una vez registrado, podremos recopilar información personal cuando utilice nuestro sitio Web.</p>
<h4 data-fontsize="21" data-lineheight="33">INFORMACIÓN QUE PODEMOS REVELAR Y DESTINATARIOS DE LA MISMA</h4>
<p>Podemos revelar la información reunida a otras organizaciones tal como se menciona más adelante. Estas organizaciones están obligadas a utilizar dicha información exclusivamente para los fines estipulados. La información puede revelarse de la siguiente manera:</p>
<h4 data-fontsize="21" data-lineheight="33">REVELACIÓN DE INFORMACIÓN A PROVEEDORES DE SERVICIOS</h4>
<p>Podemos revelar toda información personal recopilada a terceros, tales como agentes de seguros, bancos y administradores que prestan servicios a usted en representación de nuestra compañía, por ejemplo:</p>
<p>Servicios de marketing de nuestros productos y servicios;<br>
Servicios de protección contra fraude; y<br>
Servicios de mantenimiento o desarrollo de software para nuestra compañía.</p>
<p>En todos los casos, requerimos a los proveedores de servicios a quienes revelamos información personal que cumplan con nuestra Política de privacidad y utilicen la información solamente para los fines para los que han sido contratados. Revelamos información personal a estos proveedores para poder brindarle un mejor servicio.</p>
<h4 data-fontsize="21" data-lineheight="33">REVELACIÓN DE INFORMACIÓN A OTRAS COMPAÑÍAS AFILIADAS</h4>
<p>Podemos revelar datos de identificación tales como nombre, domicilio, número de teléfono e información sobre operaciones o experiencias a otras compañías afiliadas. Podemos revelar información personal a otras compañías afiliadas para los siguientes fines:</p>
<h4 data-fontsize="21" data-lineheight="33">REVELACIÓN DE OTRA INFORMACIÓN PERMITIDA POR LEY</h4>
<p>Podemos revelar información adicional según lo permita o lo requiera la ley. Por ejemplo, podemos revelar información en cumplimiento de mandamientos judiciales o administrativos.</p>
<h4 data-fontsize="21" data-lineheight="33">PROHIBICIÓN DE REVELAR INFORMACIÓN PARA OTROS FINES</h4>
<p>No compartiremos su información personal con compañías externas para fines que no sean los descritos anteriormente. Por ejemplo, no venderemos información personal a compañías externas que deseen ofrecerle sus productos y servicios. Podemos ofrecerle productos o servicios en representación de otras compañías pero no revelarles sus datos personales.</p>
<h4 data-fontsize="21" data-lineheight="33">VÍNCULOS A OTROS SITIOS</h4>
<p><a href="http://hdimexicoinsurance.com/index.aspx">hdimexicoinsurance.com</a>&nbsp;puede contener vínculos a otros sitios tales como las páginas de Internet de distribuidores y afiliadas de ventas de HDI. Aunque es nuestra intención vincularnos solamente a sitios que comparten nuestros altos estándares y respeto por la privacidad, no asumimos responsabilidad alguna por el contenido, la seguridad o las prácticas de privacidad aplicadas en otros sitios.</p>
<h4 data-fontsize="21" data-lineheight="33">SEGURIDAD DE LA INFORMACIÓN RECOPILADA</h4>
<p>Mantenemos estrictas medidas aplicables a fin de proteger su información personal contra acceso no autorizado o indebido. El acceso a la información personal está restringido a los empleados y proveedores de servicios que deban conocer la misma a fines comerciales legítimos para responder a sus dudas o solicitudes. Los empleados que incumplan nuestra Política de privacidad estarán sujetos a medidas disciplinarias.</p>
<h4 data-fontsize="21" data-lineheight="33">ACCESO A LA INFORMACIÓN RECOPILADA</h4>
<p>Puede revisar, eliminar, cancelar o actualizar la información provista a través de este sitio Web, comunicándose con nosotros de la manera indicada a continuación.</p>
<h4 data-fontsize="21" data-lineheight="33">COMUNÍQUESE CON NOSOTROS</h4>
<p>En caso de dudas sobre esta política de privacidad, utilice la página Contacto (Contacto). Le agradecemos sus preguntas y sugerencias sobre nuestra política de privacidad.</p>
<h4 data-fontsize="21" data-lineheight="33">CAMBIOS A LA PRESENTE POLÍTICA DE PRIVACIDAD</h4>
<p>Recomendamos verificar esta política de privacidad en forma periódica para mantenerse informado de cualquier cambio. Aunque nos reservamos el derecho a modificar o complementar esta política de privacidad, cualquier cambio significativo será informado en este sitio Web durante al menos 30 días posteriores al mismo.</p>
<h4 data-fontsize="21" data-lineheight="33">“OPCIONES” – OPCIONES PARA EL CONSUMIDOR SOBRE LA DISTRIBUCIÓN DE INFORMACIÓN</h4>
<p>Nos comprometemos a proteger la información personal y utilizar o compartir la misma sólo para mejorar o aumentar los servicios que le ofrecemos. Al aceptar activamente los términos de esta Política de privacidad marcando el casillero de aceptación en nuestro sitio&nbsp;<a href="http://hdimexicoinsurance.com/index.aspx">hdimexicoinsurance.com</a>, presta su consentimiento a la distribución de información personal o los informes de consumo según lo descrito anteriormente.</p>
<p>Las prácticas y políticas incluidas en esta Política de privacidad reemplazan todas las notificaciones o manifestaciones anteriores al respecto.</p>
<p>Esta Política de privacidad no se aplicará a sus relaciones con compañías que no formen parte de HDI Seguros, tales como bancos y agentes de seguros independientes. Sus políticas de privacidad regirán la manera en que recopilan, utilizan y revelan la información personal a la que les permita acceder. Esta Política de privacidad se aplica solamente a las prácticas de recopilación de información de HDI Seguros.</p>
<p>Fecha de emisión: 28/10/2009</p>`;
export default privacidad;
