import React from "react";
import PropTypes from "prop-types";
import {
  ModalBackground,
  ModalOptionContainer,
  Option,
} from "./ModalDetallePoliza.styled";
import { ReactComponent as IconoDescargar } from "../../../recursos/iconos/contigo/descargar.svg";
import { ReactComponent as IconoInfoPagos } from "../../../recursos/iconos/contigo/ico_pagar_recibos.svg";
import { ReactComponent as IconoReembolso } from "../../../recursos/iconos/contigo/ico_reembolso.svg";
import { ReactComponent as IconoReportar } from "../../../recursos/iconos/contigo/ico_alerta_verde.svg";

const diccionario = {
  etiquetaDescargarPoliza: "Descargar póliza",
  etiquetaReportar: "Reportar siniestro",
  etiquetaPagarRecibos: "Pagar recibos",
  etiquetaEliminarPoliza: "Eliminar póliza",
  consultarReembolso: "Solicitar/consultar reembolso",
  lineasNegocio: {
    AUTR: {
      nombre: "AUTR",
      descripcion: "Autos",
    },
    DAN: {
      nombre: "DAN",
      descripcion: "Daños",
    },
    GMM: {
      nombre: "GMM",
      descripcion: "Gastos Médicos Mayores",
    },
  },
  estatus: {
    Activa: "Activa",
    pendientePago: "Pendiente de pago",
    cancelada: "Cancelada",
    vencida: "Vencida",
  },
};

export default function ModalDetallePoliza({
  tipo,
  lineaNegocio,
  estatus,
  aplicaReembolso,
  showBotonReembolsos,
  closeModal,
  consultarReembolso,
  pagarRecibos,
  descargarPoliza,
  reportarSiniestro,
  eliminarPoliza,
}) {
  return (
    <>
      {tipo !== diccionario.lineasNegocio.GMM.nombre ? (
        <ModalBackground onClick={closeModal}>
          <ModalOptionContainer>
            {lineaNegocio === diccionario.lineasNegocio.AUTR.nombre && (
              <>
                {estatus === diccionario.estatus.pendientePago && (
                  <Option onClick={pagarRecibos}>
                    <IconoInfoPagos className="icon" />
                    {diccionario.etiquetaPagarRecibos}
                  </Option>
                )}
              </>
            )}

            <Option onClick={descargarPoliza}>
              <IconoDescargar className="icon" />
              {diccionario.etiquetaDescargarPoliza}
            </Option>

            {lineaNegocio === diccionario.lineasNegocio.AUTR.nombre && (
              <>
                {estatus !== diccionario.estatus.cancelada &&
                  estatus !== diccionario.estatus.vencida && (
                    <Option onClick={reportarSiniestro}>
                      <IconoReportar className="icon" />
                      {diccionario.etiquetaReportar}
                    </Option>
                  )}
              </>
            )}

            <Option onClick={eliminarPoliza} className="option delete">
              {diccionario.etiquetaEliminarPoliza}
            </Option>
          </ModalOptionContainer>
        </ModalBackground>
      ) : (
        <ModalBackground onClick={closeModal}>
          <ModalOptionContainer>
            {estatus === diccionario.estatus.Activa &&
              aplicaReembolso &&
              showBotonReembolsos && (
                <Option onClick={consultarReembolso}>
                  <IconoReembolso className="icon" />
                  {diccionario.consultarReembolso}
                </Option>
              )}
            <Option onClick={eliminarPoliza} className="option delete">
              {diccionario.etiquetaEliminarPoliza}
            </Option>
          </ModalOptionContainer>
        </ModalBackground>
      )}
    </>
  );
}

ModalDetallePoliza.propTypes = {
  tipo: PropTypes.string.isRequired,
  lineaNegocio: PropTypes.string.isRequired,
  estatus: PropTypes.string.isRequired,
  aplicaReembolso: PropTypes.bool.isRequired,
  showBotonReembolsos: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  consultarReembolso: PropTypes.func.isRequired,
  pagarRecibos: PropTypes.func.isRequired,
  descargarPoliza: PropTypes.func.isRequired,
  reportarSiniestro: PropTypes.func.isRequired,
  eliminarPoliza: PropTypes.func.isRequired,
};
