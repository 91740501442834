/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import { v4 } from "uuid";
import { EnvolvedorPantalla } from "../../componentes-styled-compartidos/Pantalla.styled";
import {
  PopupContainer,
  EvaluacionPopup,
  Titulo,
  Ordenar,
  CuerpoEvaluacion,
} from "./PantallaEvaluacion.styled";
import { ReactComponent as IconoAvion } from "../../../recursos/iconos/ico-enviado.svg";

const ModalEvaluacion = (props) => {
  const { texto } = props;

  return (
    <EnvolvedorPantalla key={v4()}>
    <PopupContainer className="modal">
      <EvaluacionPopup>
        <Ordenar style={{ padding: "0px" }}>
          <IconoAvion style={{ height: "70px", marginTop: "5%" }}></IconoAvion>
        </Ordenar>

        <Ordenar>
          <Titulo>¡GRACIAS!</Titulo>
          <CuerpoEvaluacion>Apreciamos tu feedback</CuerpoEvaluacion>
        </Ordenar>
      </EvaluacionPopup>
    </PopupContainer>
    </EnvolvedorPantalla>
  );
};

ModalEvaluacion.propTypes = {
  texto: PropTypes.string,
};

ModalEvaluacion.defaultProps = {
  texto: "Texto default",
};

export default ModalEvaluacion;
