/* eslint-disable import/prefer-default-export */
const diccionario = {
  title: "No cuento con mi póliza",
  desc: "¡No te preocupes! Podemos encontrarla mediante el RFC y el teléfono con el que fue registrada.",
  etiquetaRFC: "Ingresa tu RFC",
};

// 2

export { diccionario };
