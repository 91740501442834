/* eslint-disable */
const configAlertaError = {
  textoEncabezado: "Ocurrió un error",
  tipoIcono: "trianguloAlerta",
  colorAlerta: "amarillo",
  textoCuerpoJsx: "Tu solicitud no pudo ser procesada, por favor contacta a tu agente.",
  etiquetaBoton: "Aceptar",
};

export { configAlertaError };
