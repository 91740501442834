/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { useLazyQuery } from "@apollo/react-hooks";
import { loader } from "graphql.macro";
import useRedirect from "../../../utils/useRedirect";
import {
  EnvolvedorPoliza,
  BotonEliminarPoliza,
  EnvolvedorIdentificacion,
  EnvolvedorFotografia,
  FotografiaPoliza,
  EnvolvedorDatosIdentificacion,
  NombrePoliza,
  NumeroPoliza,
  DatoPoliza,
  TextoClaro,
  EnvolvedorEstatusPoliza,
  EstatusPoliza,
  EnvolvedorEstado,
  DiasGracia,
} from "./Poliza.styled";
import ImagenAutoDummy from "../../../recursos/iconos/hdi-c/mis-poliza/autos.png";
import ImagenDanoDummy from "../../../recursos/iconos/hdi-c/mis-poliza/danos.png";
import MaletinDummy from "../../../recursos/iconos/hdi-c/mis-poliza/gmm.png";
import constantes from "../../../recursos/constantes";
import showConfig from "../../../utils/configs";
import ModalDetallePoliza from "../../modal-detalle-poliza/modal-detalle-poliza/ModalDetallePoliza";
import { ReactComponent as IconoOpciones } from "../../../recursos/iconos/contigo/ico_opciones.svg";
import descargarPDF from "../../../utils/descargarPDF";
import { Alerta } from "../../alerta";
import { configurarAlerta } from "../../pantalla-detalle-poliza/pantalla-detalle-poliza-componente/utils";
import useAlerta from "../../../utils/useAlerta";

const diccionario = {
  poliza: "Póliza: ",
  vigencia: "Póliza vigente hasta: ",
  etiquetaReportar: "Reportar siniestro",
  etiquetaVerDetalle: "Ver detalle",
  consultarReembolso: "Solicitar/consultar reembolso",
  mensajes: {
    pendiente: "Te invitamos a ponerte al corriente",
    activa: "Tienes hasta el",
  },
  estatus: "Estatus: ",
};

const nombreCookie = constantes.nombreDeCookie;

const OBTENER_POLIZA = loader(
  "../../../graphQL/query/cobranza/cobranza_detallePolizaCobranza.graphql"
);

const OBTENER_POLIZA_PDF = loader(
  "../../../graphQL/query/poliza/obtener_polizaPDF.graphql"
);

const Poliza = (props) => {
  const {
    tipo,
    nombrePoliza,
    numeroPoliza,
    vigencia,
    estatus,
    enClickEnFoto,
    datos,
    desactivar,
    listaDiasGracia,
    aplicaReembolso,
  } = props;

  const [showOptions, setShowOptions] = useState(false);
  const history = useHistory();
  const [cookie] = useCookies([nombreCookie]);
  const dispatch = useDispatch();
  const { redirect: redirectInfoPagos } = useRedirect("/informacion-pagos");
  const objetoCookie = cookie[nombreCookie];
  if (!objetoCookie || !objetoCookie.Usuario || !objetoCookie.access_token) {
    history.push("/");
  }
  const [datoDescargar, asignarDatoDescargar] = useState({});
  const alerta = useAlerta({});
  const [etapaModal, setEtapaModal] = useState(1);
  const [modalPolizaDescargada, asignarModalPolizaDescargada] = useState(false);

  const { redirect } = useRedirect("/elegir-siniestro");
  const [diasDeGracia, asignarValorDiasDeGracia] = useState("");

  const [
    obtenerPolizaPDF,
    { loading: loadingPolizaPDF, error: errorPolizaPDF, data: dataPolizaPDF },
  ] = useLazyQuery(OBTENER_POLIZA_PDF, {
    fetchPolicy: "cache-and-network",
  });

  const [obtenerPolizaCobranza, { data: detallePoliza, loading }] =
    useLazyQuery(OBTENER_POLIZA, {
      fetchPolicy: "no-cache",
    });

  const handleOptionsClick = () => {
    setShowOptions(true);
  };

  const closeModal = () => {
    setShowOptions(false);
  };

  // const formatoDeFechas = (fecha) => moment(fecha).format("DD-MM-yyyy");
  const formatoDeFechas = (fecha) =>
    moment(fecha, "DD/MM/YYYY HH:mm:ss").format("DD-MM-yyyy");
  // eslint-disable-next-line no-unused-vars
  const verDetallePoliza = () => {
    dispatch({
      type: "BORRAR",
      indice: "informacionPolizaDetalle",
    });

    dispatch({
      type: "AGREGAR",
      valor: { ...datos, diasDeGracia },
      indice: "informacionPolizaDetalle",
    });
    history.push("/detalle-poliza", {
      poliza: { ...datos, diasDeGracia },
      diasGracia: diasDeGracia,
    });
  };

  const reportarSiniestro = () => {
    // history.push({
    //   pathname: "/ingreso-poliza",
    //   search: `?numeroPoliza=${numeroPoliza}&vin=${datos.numSerie}`,
    //   state: {
    //     paginaAnterior: "/mis-polizas",
    //   },
    // });
    redirect({
      poliza: numeroPoliza,
      tipo: datos.lineaNegocio,
      numSerie: datos.numSerie,
    });
  };

  const solicitarReembolso = () => {
    history.push({
      pathname: "/mis-reembolsos",
      state: {
        poliza: props.datos,
        paginaAnterior: "/mis-polizas",
      },
    });
  };

  const eliminarPoliza = () => {
    //  console.log("enviando..", tipo, numeroPoliza);
    desactivar(tipo, numeroPoliza);
  };

  const handleDescargarPoliza = (dato) => {
    setEtapaModal(1);
    asignarModalPolizaDescargada(true);
    asignarDatoDescargar(dato);
    obtenerPolizaCobranza({
      variables: {
        agencia: dato.oficina,
        inciso: parseInt(dato.inciso, 10),
        lineaNegocio: dato.lineaNegocio,
        numeroReporte: 0,
        poliza: dato.poliza,
        token: objetoCookie.access_token,
      },
    });
  };

  const descargarPoliza = (datosDescargarPoliza) => {
    obtenerPolizaPDF({
      variables: {
        usuario: datosDescargarPoliza.usuario,
        lineaNegocio: datosDescargarPoliza.lineaNegocio,
        oficina: datosDescargarPoliza.oficina,
        poliza: datosDescargarPoliza.poliza,
        certificado: datosDescargarPoliza.certificado,
        documento: datosDescargarPoliza.documento,
        tipoReporte: datosDescargarPoliza.tipoReporte,
        token: datosDescargarPoliza.token,
      },
    });
  };

  useEffect(() => {
    const datoPoliza = listaDiasGracia?.find(
      (el) => el.poliza === datos.poliza
    );
    if (datoPoliza) {
      if (datoPoliza.notificacionDias.split(" ")[1] < 16) {
        asignarValorDiasDeGracia(datoPoliza.notificacionDias);
      }
    }
  }, []);

  useEffect(() => {
    if (!loading) {
      if (detallePoliza && detallePoliza.cobranza_detallePolizaCobranza) {
        const res = detallePoliza.cobranza_detallePolizaCobranza;
        if (res.completado) {
          const tmpPoliza = res.dato;
          const endoso = tmpPoliza.endosos.find(
            (el) => el.movimiento === "PÓLIZA"
          );

          if (endoso) {
            try {
              console.log("Endoso", endoso);
              const datosDescargarPoliza = {
                usuario: objetoCookie.Usuario,
                lineaNegocio: datoDescargar.lineaNegocio,
                oficina: datoDescargar.oficina,
                poliza: datoDescargar.poliza,
                certificado: endoso.certificado,
                documento: endoso.endoso,
                tipoReporte: "P",
                token: objetoCookie.access_token,
              };

              descargarPoliza(datosDescargarPoliza);
            } catch (error) {
              setEtapaModal(6);
              alerta.mostrar();
            }
          }
        }
      }
    }
  }, [detallePoliza, loading]);

  // ! DESCARGA DE DOCUMENTOS
  useEffect(() => {
    if (!loadingPolizaPDF) {
      const respuesta = dataPolizaPDF?.obtener_polizaPDF;
      if (respuesta) {
        if (respuesta.dato) {
          descargarPDF(respuesta.dato, datoDescargar.poliza, "pdf")
            .then((res) => {
              if (res !== 200) {
                setEtapaModal(6);
                alerta.mostrar();
              }
            })
            .catch(() => {
              setEtapaModal(6);
              alerta.mostrar();
            });
        } else {
          setEtapaModal(6);
          alerta.mostrar();
        }
      }
    }
    if (errorPolizaPDF) {
      setEtapaModal(6);
      alerta.mostrar();
    }
  }, [dataPolizaPDF, loadingPolizaPDF, errorPolizaPDF]);

  useEffect(() => {
    alerta.actualizar(configurarAlerta(etapaModal));
    if (etapaModal === 6) {
      alerta.mostrar();
    }
  }, [etapaModal]);

  const asignarIcono = () => {
    if (tipo === "AUTR") {
      return ImagenAutoDummy;
    }
    if (tipo === "GMM") {
      return MaletinDummy;
    }
    return ImagenDanoDummy;
  };

  const asignarEstadoPolizaGMM = (estado) => {
    if (estado === "Activo") {
      return "Activa";
    }
    return "Cancelada";
  };

  return (
    <EnvolvedorPoliza>
      <Alerta
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...alerta}
        manejarCierre={() => alerta.cerrar()}
        funcionLlamadaBoton={() => alerta.cerrar()}
        funcionLlamadaBoton2={() => alerta.cerrar()}
      />

      <Alerta
        mostrarModal={modalPolizaDescargada}
        manejarCierre={() => asignarModalPolizaDescargada(false)}
        textoEncabezado="Atención"
        textoCuerpo="Tu póliza se descargará en breve."
        colorAlerta="amarillo"
        funcionLlamadaBoton={() => asignarModalPolizaDescargada(false)}
        etiquetaBoton="Entiendo"
      />
      {tipo !== "GMM" ? (
        <>
          <EnvolvedorIdentificacion>
            <BotonEliminarPoliza onClick={handleOptionsClick}>
              <IconoOpciones className="ico" />
            </BotonEliminarPoliza>
            <EnvolvedorFotografia onClick={verDetallePoliza}>
              <FotografiaPoliza
                src={asignarIcono()}
                onClick={() => enClickEnFoto()}
              />
            </EnvolvedorFotografia>
            <EnvolvedorDatosIdentificacion onClick={verDetallePoliza}>
              <NombrePoliza>{nombrePoliza}</NombrePoliza>
            </EnvolvedorDatosIdentificacion>
          </EnvolvedorIdentificacion>

          <EnvolvedorEstado onClick={verDetallePoliza}>
            <NumeroPoliza>{`${diccionario.poliza} ${numeroPoliza}`}</NumeroPoliza>

            <DatoPoliza>{`${diccionario.vigencia} ${vigencia}`}</DatoPoliza>

            {datos.mensajePago && datos.mensajePago.trim() !== "" && (
              <TextoClaro completo>{datos.mensajePago}</TextoClaro>
            )}

            {diasDeGracia.length > 0 &&
              estatus.toUpperCase() !== "CANCELADA" && (
                <DiasGracia completo>{diasDeGracia}</DiasGracia>
              )}

            <DatoPoliza>
              {diccionario.estatus}
              <EnvolvedorEstatusPoliza estatus={estatus}>
                <EstatusPoliza estatus={estatus} />
                {estatus}
              </EnvolvedorEstatusPoliza>
            </DatoPoliza>
          </EnvolvedorEstado>
        </>
      ) : (
        <>
          <EnvolvedorIdentificacion>
            <BotonEliminarPoliza onClick={handleOptionsClick}>
              <IconoOpciones className="ico" />
            </BotonEliminarPoliza>
            <EnvolvedorFotografia onClick={verDetallePoliza}>
              <FotografiaPoliza
                src={asignarIcono()}
                onClick={() => enClickEnFoto()}
              />
            </EnvolvedorFotografia>
            <EnvolvedorDatosIdentificacion onClick={verDetallePoliza}>
              <NombrePoliza>{nombrePoliza}</NombrePoliza>
            </EnvolvedorDatosIdentificacion>
          </EnvolvedorIdentificacion>
          <EnvolvedorEstado onClick={verDetallePoliza}>
            <DatoPoliza>
              <NumeroPoliza>{numeroPoliza}</NumeroPoliza>
            </DatoPoliza>

            <DatoPoliza>
              <NumeroPoliza>Gastos Médicos Mayores</NumeroPoliza>
            </DatoPoliza>

            <DatoPoliza>
              {`${diccionario.vigencia} ${formatoDeFechas(vigencia)}`}
            </DatoPoliza>

            {datos.mensajePago && datos.mensajePago.trim() !== "" && (
              <TextoClaro completo>{datos.mensajePago}</TextoClaro>
            )}

            {diasDeGracia.length > 0 ? (
              <DiasGracia completo>{diasDeGracia}</DiasGracia>
            ) : null}

            <DatoPoliza>
              {diccionario.estatus}
              <EnvolvedorEstatusPoliza estatus={estatus}>
                <EstatusPoliza estatus={asignarEstadoPolizaGMM(estatus)} />
                {asignarEstadoPolizaGMM(estatus)}
              </EnvolvedorEstatusPoliza>
            </DatoPoliza>
          </EnvolvedorEstado>
        </>
      )}
      {showOptions && (
        <ModalDetallePoliza
          tipo={tipo}
          lineaNegocio={datos.lineaNegocio}
          estatus={datos.estatus}
          aplicaReembolso={aplicaReembolso}
          showBotonReembolsos={showConfig.showBotonReembolsos}
          closeModal={closeModal}
          consultarReembolso={solicitarReembolso}
          pagarRecibos={() => {
            redirectInfoPagos({
              poliza: datos,
              tipo: datos.lineaNegocio,
              modo: "Recibos",
            });
          }}
          descargarPoliza={() => handleDescargarPoliza(datos)}
          reportarSiniestro={reportarSiniestro}
          eliminarPoliza={eliminarPoliza}
        />
      )}
    </EnvolvedorPoliza>
  );
};

Poliza.defaultProps = {
  tipo: "AUTR",
  numeroPoliza: "XXX-XX-XXX",
  nombrePoliza: "CASA HABITACIÓN",
  vigencia: "DD/MM/YYY",
  estatus: "Activa",
  enClickEnFoto: () => {},
  datos: {},
  desactivar: () => {},
  listaDiasGracia: {},
  aplicaReembolso: false,
};

Poliza.propTypes = {
  tipo: PropTypes.string,
  enClickEnFoto: PropTypes.func,
  numeroPoliza: PropTypes.string,
  nombrePoliza: PropTypes.string,
  vigencia: PropTypes.string,
  estatus: PropTypes.string,
  datos: PropTypes.oneOfType({}),
  desactivar: PropTypes.func,
  listaDiasGracia: PropTypes.oneOfType({}),
  aplicaReembolso: PropTypes.bool,
};

export default Poliza;
