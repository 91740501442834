const configAlertaError = {
  textoEncabezado: "Ocurrió un error",
  tipoIcono: "trianguloAlerta",
  colorAlerta: "amarillo",
  textoCuerpoJsx:
    "Tu credencial no pudo ser descargada, por favor contactar al administrador.",
  etiquetaBoton: "Aceptar",
};

export default configAlertaError;
