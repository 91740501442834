import styled from "styled-components";
import {
  Titulo3,
  Subtitulo3,
} from "../../componentes-styled-compartidos/Textos";

const TituloMenuEspera = styled(Titulo3)`
  margin-bottom: 60px;
  margin-top: 20px;
`;

const ContenedorOpcion = styled(Subtitulo3)`
  width: 100%;
  cursor: pointer;
  display: grid;
  align-items: center;
  border-radius: 4px;
  border: solid 1px var(--color-gris-claro);
  box-shadow: 0 3px 10px 0 rgba(48, 55, 56, 0.15);
  font-family: var(--fuente-proxima-bold);
  margin-bottom: 30px;
  padding: 20px;
`;

const EnvolvedorFotografia = styled.div`
  grid-column: 1;
  box-sizing: border-box;
  height: 65px;
  width: 65px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  box-sizing: border-box;
`;
const EnvolvedorDatosOpcion = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  grid-column: 2;
  box-sizing: border-box;
  height: auto;
`;

const TituloOpcion = styled.div`
  display: flex;
  flex-direction: row;
  font-family: var(--fuente-proxima-bold);
  font-size: 16px;
  color: var(--color-negro-puro);
`;

const DatoOpcion = styled.div`
  display: flex;
  flex-direction: row;
  color: var(--color-gris-medio);
  @media (max-width: 260px) {
    font-size: 12px;
  }
`;

const LogoOpcion = styled.img`
  float: left;
  padding-right: 18px;
`;

export {
  TituloMenuEspera,
  ContenedorOpcion,
  LogoOpcion,
  EnvolvedorFotografia,
  EnvolvedorDatosOpcion,
  DatoOpcion,
  TituloOpcion,
};
