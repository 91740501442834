/* eslint-disable import/extensions */
/* eslint-disable react/prop-types */
import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
// import SiguienteIcono from "@material-ui/icons/NavigateNext";
import {
  Contenedor,
  ContenidoAcordeon,
  Encabezado,
  EnvolvedorIcono,
  EnvolvedorImagen,
  ParrafoAcordeon,
  // TituloAcordeon,
} from "../../acordeon-registro-poliza/acordeon-registro-poliza-componente/AcordeonRegistroPoliza.styled";
import icoAtencion from "../../../recursos/iconos/ico-user-ajustador.svg";
import icoReparacion from "../../../recursos/iconos/ico-auto-engrane.svg";
import { Dato } from "./interfaces/IElegirEncuesta";
import { NombrePoliza } from "../../caja-resumen/caja-resumen-componente/CajaResumen.styled";

interface IProps {
  survey: Dato;
  reporte: number;
  siniestro: number;
  fechareporte?: string;
}

const CardPendingSurvey = ({
  survey,
  reporte,
  siniestro,
  fechareporte,
}: IProps) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const reedirigir = () => {
    console.log(siniestro);
    dispatch({
      type: "AGREGAR",
      indice: "numeroSiniestroReporte",
      valor: Number(siniestro),
    });

    if (survey.encuesta === 1) {
      history.push(`/evaluacion-ajustador?numeroReporte=${reporte}`, {
        omitida: true,
      });
      return;
    }

    history.push(`/evaluacion-taller?numeroReporte=${reporte}`, {
      omitida: true,
    });
  };

  const obtenerIcono = (surveyType: number) => {
    if (surveyType === 1) {
      return icoAtencion;
    }

    return icoReparacion;
  };

  return (
    <>
      <Contenedor style={{ padding: "10px" }} show onClick={() => reedirigir()}>
        <Encabezado>
          <EnvolvedorIcono>
            <EnvolvedorImagen src={obtenerIcono(survey.encuesta)} />
          </EnvolvedorIcono>
          <ContenidoAcordeon>
            <ParrafoAcordeon>
              {survey.encuesta === 1
                ? `Califica a tu ajustador`
                : `Califica tu centro de reparación`}
            </ParrafoAcordeon>
            <NombrePoliza>
              {survey.encuesta === 1 ? survey.evaluado : survey.evaluado}
            </NombrePoliza>
            <ParrafoAcordeon>{fechareporte}</ParrafoAcordeon>
          </ContenidoAcordeon>
        </Encabezado>
      </Contenedor>
      {/* aqui va lo mio */}

      {/* <ContenedorParrafo>
        <EnvolvedorIcono>
          <EnvolvedorImagen src={obtenerIcono(survey.encuesta)} />
        </EnvolvedorIcono>
        <LigaEtiqueta id="chanfle" onClick={() => reedirigir()}>
          {survey.encuesta === 1
            ? `Califica a tu ajustador`
            : `Califica tu centro de reparación`}
        </LigaEtiqueta>
        <SiguienteIcono
          onClick={() => {
            history.push("/contrasena-cambiar");
          }}
        />
      </ContenedorParrafo> */}

      {/* aqui termina lo mio */}
    </>
  );
};

export default CardPendingSurvey;
